import { mainApi } from "services/main";
import {
  GetTaskByIdResponse,
  GetTaskByIdRequest,
  DeleteReviewRequest,
  RemindReviewRequest,
  EvaluateReviewRequest,
} from "./definitions";
import { UpdateReviewOutput } from "components/edit-review-form/definitions";

const extendedApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getTaskById: builder.query<GetTaskByIdResponse, GetTaskByIdRequest>({
      query: (request: GetTaskByIdRequest) => ({
        url: "tasks/" + request.taskId,
      }),
    }),
    evaluateReview: builder.mutation<UpdateReviewOutput, EvaluateReviewRequest>(
      {
        query: (request: EvaluateReviewRequest) => {
          const { reviewId, ...bodyData } = request;
          return {
            url: `reviews/${reviewId}/evaluate`,
            body: request,
            method: "PUT",
          };
        },
      }
    ),
    deleteReview: builder.mutation<void, DeleteReviewRequest>({
      query: (request: DeleteReviewRequest) => {
        const { reviewId, ...bodyData } = request;
        return {
          url: `reviews/${reviewId}/soft-delete`,
          body: request,
          method: "PUT",
        };
      },
    }),

    remindReview: builder.mutation<void, RemindReviewRequest>({
      query: (request: RemindReviewRequest) => {
        const { reviewId, ...bodyData } = request;
        return {
          url: `reviews/${reviewId}/remind`,
          body: request,
          method: "PUT",
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTaskByIdQuery,
  useDeleteReviewMutation,
  useEvaluateReviewMutation,
  useRemindReviewMutation,
} = extendedApi;
