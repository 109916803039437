import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IUiContentState,
  ListMemberResponse,
  TeamMember,
  UIContentResponse,
} from "./definitions";

type BaseOption = {
  label: string | undefined;
  value: string | undefined;
  description?: string;
  labelTag?: string;
};

const initialState: IUiContentState = {
  countryOptions: [{ label: "No country", value: "No country" }],
  entityOptions: [{ label: "No entity", value: "No entity" }],
  governmentEntityOptions: [
    { label: "No tax authority", value: "No tax authority" },
  ],
  taxTypeOptions: [{ label: "No tax type", value: "No tax type" }],
  ownerOptions: [{ label: "No owner", value: "No owner" }],
  criticalOwnerOptions: [{ label: "No owner", value: "No owner" }],
  categoryOptions: [{ label: "No category", value: "No category" }],
  assessmentTypeOptions: [
    { label: "No assessment type", value: "No assessment type" },
  ],
  paymentTypeOptions: [{ label: "No payment type", value: "No payment type" }],
  documentTypeOptions: [
    { label: "No document type", value: "No document type" },
  ],
  securityLevelOptions: [
    { label: "No security level type", value: "No security level type" },
  ],
  taskGroupTypeOptions: [
    { label: "No task group type", value: "No task group type" },
  ],
  inquiryTypeOptions: [{ label: "No type", value: "No type" }],
  subInquiryTypeOptions: [{ label: "No subtype", value: "No subtype" }],
  complexityOptions: [
    { label: "No complexity type", value: "No complexity type" },
  ],
  priorityOptions: [{ label: "No priority type", value: "No priority type" }],
  taskStatusOptions: [{ label: "No task status", value: "No task status" }],
  actionOwnerOptions: [{ label: "No Action Owner", value: "No Action Owner" }],
};

const mapOptions = (values: Array<string>) => {
  return values
    .map((value: string) => ({
      label: value,
      value: value,
    }))
    .sort((a: { label: string }, b: { label: string }) =>
      a.label > b.label ? 1 : -1
    );
};

const mapMembers = (values: Array<TeamMember>) => {
  return values
    .map((value: TeamMember) => ({
      label: value.alias,
      value: value.alias,
    }))
    .sort((a: { label: string }, b: { label: string }) =>
      a.label > b.label ? 1 : -1
    );
};

const mapCriticalMembers = (values: Array<TeamMember>) => {
  return values
    .filter((value: TeamMember) => value.isCritical)
    .map((value: TeamMember) => ({
      label: value.alias,
      value: value.alias,
    }))
    .sort((a: { label: string }, b: { label: string }) =>
      a.label > b.label ? 1 : -1
    );
};

const mapOptionsUnsort = (values: Array<string>) => {
  return values.map((value: string) => ({
    label: value,
    value: value,
  }));
};

const mapOptionsWithGroup = (values: Array<BaseOption>) => {
  var items: { label: string | undefined; options: BaseOption[] }[] = [];
  var groupNames = new Set(values.map((value) => value.labelTag));
  groupNames.forEach((groupName) => {
    var entries: Array<BaseOption> = [];
    values.forEach((value) => {
      if (value.labelTag === groupName) {
        var entry = {
          label: value.label ?? "",
          value: value.value ?? "",
          description: value.value ?? "",
        };

        entries.push(entry);
      }
    });
    entries.sort((a: BaseOption, b: BaseOption) => {
      if (a.label === undefined) return 1;
      if (b.label === undefined) return -1;

      return a.label > b.label ? 1 : -1;
    });
    var group = {
      label: groupName,
      options: entries,
    };

    items.push(group);
  });
  return items;
};

const mapOptionsObj = (values: Array<BaseOption>) => {
  return values
    .map((value: BaseOption) => ({
      label: value.label ?? "",
      value: value.value ?? "",
      description: value.value ?? "",
    }))
    .sort((a: { label: string }, b: { label: string }) =>
      a.label > b.label ? 1 : -1
    );
};

const mapOptionsLayerUnsort = (values: Array<BaseOption>) => {
  const items = Array.from(
    values.reduce((groups, value) => {
      const group = groups.get(value.label) || [];
      group.push({ label: value.value, value: value.value });
      groups.set(value.label, group);
      return groups;
    }, new Map<string | undefined, BaseOption[]>()),
    ([label, options]) => ({ label, options })
  );
  return items;
};

export const uiContentSlice = createSlice({
  name: "uiContent",
  initialState,
  reducers: {
    uiContentLoaded: (state, action: PayloadAction<UIContentResponse>) => {
      if (action.payload.content === null) {
        return;
      }
      const uiContent = JSON.parse(action.payload.content);
      if (uiContent.country !== undefined)
        state.countryOptions = mapOptions(uiContent.country);

      if (uiContent.entity !== undefined)
        state.entityOptions = mapOptionsObj(uiContent.entity);

      if (uiContent.governmententity !== undefined)
        state.governmentEntityOptions = mapOptionsWithGroup(
          uiContent.governmententity
        );

      if (uiContent.taxtype !== undefined)
        state.taxTypeOptions = mapOptions(uiContent.taxtype);
      if (uiContent.category !== undefined)
        state.categoryOptions = mapOptions(uiContent.category);
      if (uiContent.assessmenttype !== undefined)
        state.assessmentTypeOptions = mapOptions(uiContent.assessmenttype);
      if (uiContent.paymenttype !== undefined)
        state.paymentTypeOptions = mapOptions(uiContent.paymenttype);
      if (uiContent.documenttype !== undefined)
        state.documentTypeOptions = mapOptionsUnsort(uiContent.documenttype);
      if (uiContent.securitylevel !== undefined)
        state.securityLevelOptions = mapOptions(uiContent.securitylevel);
      if (uiContent.taskgrouptype !== undefined)
        state.taskGroupTypeOptions = mapOptions(uiContent.taskgrouptype);
      if (uiContent.inquirytype !== undefined)
        state.inquiryTypeOptions = mapOptions(uiContent.inquirytype);
      if (uiContent.subinquirytype !== undefined)
        state.subInquiryTypeOptions = mapOptionsLayerUnsort(
          uiContent.subinquirytype
        );
      if (uiContent.complexity !== undefined)
        state.complexityOptions = mapOptions(uiContent.complexity);
      if (uiContent.priority !== undefined)
        state.priorityOptions = mapOptions(uiContent.priority);
      if (uiContent.taskstatus !== undefined)
        state.taskStatusOptions = mapOptions(uiContent.taskstatus);
      if (uiContent.actionOwner !== undefined)
        state.actionOwnerOptions = mapOptionsUnsort(uiContent.actionOwner);
    },
    teamMemberLoaded: (state, action: PayloadAction<ListMemberResponse>) => {
      if (action.payload.members === null) {
        return;
      }
      state.ownerOptions = mapMembers(action.payload.members);
      state.criticalOwnerOptions = mapCriticalMembers(action.payload.members);
    },
  },
});

export const { uiContentLoaded, teamMemberLoaded } = uiContentSlice.actions;

export default uiContentSlice.reducer;
