import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAuditsState } from "./definitions";

const initialState: IAuditsState = {
  taskList: [],
  taskGroupRatios: [],
  assessmentList: [],
  paymentList: [],
  selectedAudit: {},
  teamName: "",
  selectedTask: {},
  selectedAssessment: {
    tax: "",
    penalty: "",
    interest: "",
    other: "",
    creatingPaymentFromAssessment: false,
    taxAreaBreakdowns: [],
    taxYearBreakdowns: [],
  },
};

export const auditsSlice = createSlice({
  name: "audits",
  initialState,
  reducers: {
    removeSelectedAudit: (state) => {
      state.selectedAudit = {};
    },
    getAuditById: (state, action) => {
      return {
        ...state,
        selectedAudit: action.payload,
      };
    },
    addAssessmentsToList: (state, action) => {
      return {
        ...state,
        assessmentList: [...state.assessmentList, ...action.payload],
      };
    },
    deleteSingleAssessmentFromList: (state, action) => {
      return {
        ...state,
        assessmentList: state.assessmentList.filter(
          (assessment) => assessment.assessmentId !== action.payload
        ),
      };
    },
    setAssessmentList: (state, action) => {
      return {
        ...state,
        assessmentList: [...action.payload],
      };
    },
    updateSingleAssessment: (state, action) => {
      return {
        ...state,
        assessmentList: state.assessmentList.map((assessment) => {
          if (assessment.assessmentId === action.payload.assessmentId) {
            return { ...assessment, ...action.payload };
          }
          return assessment;
        }),
      };
    },
    addPaymentsToList: (state, action) => {
      return {
        ...state,
        paymentList: [...state.paymentList, ...action.payload],
      };
    },
    deleteSinglePaymentFromList: (state, action) => {
      return {
        ...state,
        paymentList: state.paymentList.filter(
          (payment) => payment.paymentId !== action.payload
        ),
      };
    },
    setPaymentList: (state, action) => {
      return {
        ...state,
        paymentList: [...action.payload],
      };
    },
    updateSinglePayment: (state, action) => {
      return {
        ...state,
        paymentList: state.paymentList.map((payment) => {
          if (payment.paymentId === action.payload.paymentId) {
            return { ...payment, ...action.payload };
          }
          return payment;
        }),
      };
    },
    addTasksToList: (state, action) => {
      return { ...state, taskList: [...state.taskList, ...action.payload] };
    },
    deleteSingleTaskFromList: (state, action) => {
      return {
        ...state,
        taskList: state.taskList.filter(
          (task) => task.taskId !== action.payload
        ),
      };
    },
    removeTasksFromList: (state, action) => {
      return {
        ...state,
        taskList: state.taskList.filter(
          (task) => task.parentTaskId !== action.payload
        ),
      };
    },
    updateSingleTask: (state, action) => {
      return {
        ...state,
        taskList: state.taskList.map((task) => {
          if (task.taskId === action.payload.taskId) {
            return { ...task, ...action.payload };
          }
          return task;
        }),
      };
    },
    updateTaskGroupRatio: (state, action) => {
      const ratios = state.taskGroupRatios.filter(
        (e) => e.taskId !== action.payload.taskId
      );
      ratios.unshift(action.payload);
      return {
        ...state,
        taskGroupRatios: ratios,
      };
    },
    addTaskGroupRatio: (state, action) => {
      return {
        ...state,
        taskGroupRatios: [...state.taskGroupRatios, ...action.payload],
      };
    },
    teamNameUpdated: (state, action: PayloadAction<string>) => {
      state.teamName = action.payload;
    },
    removeSelectedTask: (state) => {
      state.selectedTask = {};
    },
    getTaskById: (state, action) => {
      return {
        ...state,
        selectedTask: action.payload,
      };
    },
    setSelectedAssessment: (state, action) => {
      return {
        ...state,
        selectedAssessment: {
          tax: action.payload.tax || "",
          penalty: action.payload.penalty || "",
          interest: action.payload.interest || "",
          other: action.payload.other || "",
          creatingPaymentFromAssessment:
            action.payload.creatingPaymentFromAssessment || false,
          taxAreaBreakdowns: action.payload.taxAreaBreakdowns,
          taxYearBreakdowns: action.payload.taxYearBreakdowns,
        },
      };
    },
    resetSelectedAssessment: (state) => {
      return {
        ...state,
        selectedAssessment: {
          tax: "",
          penalty: "",
          interest: "",
          other: "",
          creatingPaymentFromAssessment: false,
          taxAreaBreakdowns: [],
          taxYearBreakdowns: [],
        },
      };
    },
  },
});

export const {
  removeSelectedAudit,
  getAuditById,
  teamNameUpdated,
  addTasksToList,
  removeTasksFromList,
  deleteSingleTaskFromList,
  updateSingleTask,
  updateTaskGroupRatio,
  addTaskGroupRatio,
  getTaskById,
  removeSelectedTask,
  addAssessmentsToList,
  deleteSingleAssessmentFromList,
  setAssessmentList,
  updateSingleAssessment,
  addPaymentsToList,
  deleteSinglePaymentFromList,
  setPaymentList,
  updateSinglePayment,
  setSelectedAssessment,
  resetSelectedAssessment,
} = auditsSlice.actions;

export default auditsSlice.reducer;
